<template>
  <div id="container">
    <Navbar @lang="getLang" color="white" />
    <div id="content-wrapper">
        <CampaignCase type="CPL" timeframe="6 Months" banner="abbot/Group 383-min.png" logo="abbot/abbott-logo.png"/>
    </div>
    <DetailCampaign :lang="lang" contentdetail="abbott" :text="lang === 'id' ? textDetail: textDetailEn"/>
    <CampaignDemo :text="lang === 'id' ? textDemo : textDemoEn" :demo="demo"/>
    <CampaignEx :lang="lang" :ex="campaignEx"/>
    <Footer />
  </div>
</template>

<script>
import Navbar from '@/components/Navbar'
import Footer from '@/components/Footer'
import CampaignCase from '@/components/CaseStudy/CampaignCase'
import DetailCampaign from '@/components/CaseStudy/DetailCampaign'
import CampaignDemo from '@/components/CaseStudy/CampaignDemo'
import CampaignEx from '@/components/CaseStudy/CampaignEx'
export default {
  components: {
    Navbar,
    Footer,
    CampaignCase,
    DetailCampaign,
    CampaignDemo,
    CampaignEx
  },
  data () {
    return {
      lang: '',
      textDetail: 'Memiliki 3 produk dengan segmentasi yang berbeda-beda, HIGO membuat strategi campaign lewat satu survei. Menargetkan wanita yang sudah memiliki anak, setiap wanita yang terkoneksi WiFi akan mendapat pertanyaan tentang "Jumlah Anak". Setiap audien yang telah menyelesaikan survei akan dianggap menjadi satu lead',
      textDetailEn: 'With 3 different products targeted to different audience segmentation, HIGO created a campaign strategy through simple survey. Since Abbott targeted women with children, every female user connected to WiFi will be asked the number of childern that they have. Each user who has completed the survey will be considered as a lead',
      textDemo: 'Setelah mengisi survei di bagian awal, setiap audien akan terpapar iklan sesuai dengn produk yang ditargetkan berdasarkan usia. Goal dari campaign ini adalah mengumpulkan Fresh Leads yang akan digunakan untuk CRM oleh tim marketing Abbott',
      textDemoEn: "After completing the survey at the beginning, each audience will be exposed to an advertisement according to an age-targeted product. The goal of this campaign is to collect Fresh Leads which will be used for CRM by Abbott's marketing team",
      demo: {
        first: {
          image: 'abbot/mockup-1-min-min.png',
          text: 'Survei Pediasure'
        },
        second: {
          image: 'abbot/mc2-min-min.png',
          text: 'Survei Ensure'
        },
        third: {
          image: 'abbot/Mask Group 15-min-min.png',
          text: 'YouTube'
        }
      },
      campaignEx: {
        text: 'HIGO bahkan menyumbang kenaikan traffic sebesar 33% viewers ke YouTube milik Abbott dari direct link yang dilakukan',
        textEn: "HIGO contributed 33% increase in traffic viewers to Abbott's YouTube from the direct link carried out",
        image: 'abbot/Mask Group 14-min-min.png'
      }
    }
  },
  methods: {
    getLang (value) {
      this.lang = value
      this.loading = true
      setTimeout(() => {
        this.loading = false
      }, 550)
    }
  }
}
</script>

<style scoped>
#container {
  width: 100%;
  min-height: calc(100vh - 3rem);
  overflow-x: hidden;
}

#content-wrapper {
  padding-top: 3rem;
  position: relative;
  height: auto;
  /* min-height: calc(100vh - 3rem);
  max-height: 100vh; */
  width: 100%;
}
</style>
